import React from "react";
import { createStyles, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";

//SEN components
import theme from "../../theme";
import { AuthContext } from "../authProvider";
import { login } from "../../dataProvider";
import HomeBanner from "./HomeBanner";

const styles = () =>
	createStyles({
		root: {
			width: "100%",
			padding: "0px 40px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			borderRadius: "5px",
			color: theme.palette.primary.main,
			"@media (max-width: 780px)": {
				margin: "0px",
				padding: "0px 0px",
			},
		},
		fullHeight: {
			height: "100vh",
			padding: "0px",
		},
		w100: {
			width: "100%",
		},
		w50: {
			width: "50%",
		},
		p40: {
			padding: "40px",
		},
		paddingOutline: {
			padding: "40px",
			"@media (max-width: 700px)": {
				padding: "10px",
			},
		},
		floatLeft: {
			display: "inline",
			float: "left",
		},
		floatRight: {
			display: "inline",
			textAlign: "right",
			float: "right",
		},
		inline: {
			display: "inline",
		},
		headerBold: {
			fontWeight: 800,
			fontSize: "60px",
		},
		paddingLRBoxR: {
			padding: "0 5% 0 calc(5% + 40px)",
			"@media (max-width: 1280px)": {
				padding: "40px 7.5%",
			},
			"@media (max-width: 700px)": {
				paddingBottom: "0px",
			},
		},
		paddingLRBoxL: {
			padding: "0 12.5% 0 12.5%",
			"@media (max-width: 1920px)": {
				padding: "0 5% 0 5%",
			},
			"@media (max-width: 1280px)": {
				padding: "40px 7.5%",
			},
			"@media (max-width: 700px)": {
				paddingBottom: "0px",
			},
		},
		paddingLR12Percent: {
			padding: "0 12.5%",
			"@media (max-width: 1280px)": {
				padding: "40px 7.5%",
			},
			"@media (max-width: 700px)": {
				paddingBottom: "0px",
			},
		},
		paddingBottom20: {
			paddingBottom: "20px",
		},
		marginBottom5: {
			marginBottom: "5px",
		},
		marginBottom20: {
			marginBottom: "20px",
		},
		marginBottom30: {
			marginBottom: "30px",
		},
		verticalAlign: {
			display: "flex",
			alignItems: "center",
		},
		textCenter: { textAlign: "center" },
		submitButton: {
			background: theme.palette.primary.main,
			color: "#fff",
			fontWeight: "bold",
			fontSize: "22px",
			height: "60px",
			textTransform: "none",
			"&:hover": {
				background: "transparent",
				border: "1px solid " + theme.palette.primary.main,
				color: theme.palette.primary.main,
			},
		},
		colorTertiary: {
			color: theme.palette.primary.main,
		},
		secondaryButton: {
			color: theme.palette.primary.main,
			fontWeight: "bold",
			fontSize: "22px",
			height: "60px",
			textTransform: "none",
		},
		inputText: {
			borderColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
			borderWidth: "20px",

			"& label": {
				color: theme.palette.primary.main,
				fontSize: "25px",
			},
			"& .Mui-focused": {
				color: theme.palette.primary.main,
			},
			"& .MuiInputBase-root": {
				color: theme.palette.primary.main,
				height: "60px",
			},
			"& .MuiInput-underline:after": {
				color: theme.palette.primary.main,
				borderBottomColor: theme.palette.primary.main,
			},
			"& .MuiOutlinedInput-root": {
				fontSize: "25px",

				"& fieldset": {
					color: theme.palette.primary.main,
					borderColor: theme.palette.primary.main,
				},
				"&:hover fieldset": {
					color: theme.palette.primary.main,
					borderColor: theme.palette.primary.main,
				},
				"&.Mui-focused fieldset": {
					color: theme.palette.primary.main,
					borderColor: theme.palette.primary.main,
				},
			},
		},
		bgImage: {
			width: "100vw",
			overflow: "visible",
		},
		hideMobile: {
			"@media (max-width: 780px)": {
				display: "none",
			},
		},
		showMobile: {
			display: "none",
			"@media (max-width: 780px)": {
				display: "block",
			},
		},
		bg: {
			backgroundImage: theme.customColors.gradient.main,
			backgroundRepeat: "no-repeat",
			borderRadius: "5px",
			"@media (max-width: 959px)": {
				backgroundImage: theme.customColors.gradient.main,
			},
		},
		bottomDiv: {
			bottom: "0px",
			position: "absolute",
			width: "75%",
			textAlign: "center",

			"@media (max-width: 960px)": {
				width: "86%",
				bottom: "25px",
			},

			"@media (max-width: 780px)": {
				width: "88%",
				bottom: "25px",
			},
		},
		relative: {
			position: "relative",
			height: "100%",
		},
		link: {
			textDecoration: "none",
			marginRight: "7px",
			color: theme.palette.primary.main,
		},
	});

interface LoginProps {
	classes: any;
}

interface LoginState {
	emailAddress: string;
	password: string;
	loading: boolean;
	error: string;
}

class Login extends React.Component<LoginProps, LoginState> {
	static contextType = AuthContext;

	constructor(props: LoginProps) {
		super(props);
		this.state = {
			emailAddress: "",
			password: "",
			loading: false,
			error: "",
		};
	}

	async handleLogin(emailAddress: string, password: string) {
		//Autocomplete check
		if (!emailAddress) {
			let el = document.getElementById("standard-email-input") as HTMLInputElement;
			if (el) {
				console.log(el.value);
				emailAddress = el.value;
			}
		}

		if (!password) {
			let el2 = document.getElementById("standard-password-input") as HTMLInputElement;
			if (el2) {
				console.log(el2.value);
				password = el2.value;
			}
		}
		this.setState({ loading: true });
		try {
			await login(emailAddress, password);
			this.context.setAuthenticated(true);
			this.context.setRole(localStorage.getItem("role"));
		} catch (err) {
			this.context.setAuthenticated(false);
			this.context.setRole("default");
			this.setState({ error: "Incorrect email/password combination, please try again." });
		}
		this.setState({ loading: false });
	}

	render() {
		const { classes } = this.props;

		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className={classes.bgImage}>
					<Grid
						container
						className={[classes.paddingOutline, classes.fullHeight].join(" ")}
					>
						<Grid
							item
							md={6}
							sm={12}
							xs={12}
							className={[
								classes.p40,
								classes.verticalAlign,
								classes.paddingLRBoxL,
								classes.bg,
							].join(" ")}
						>
							<HomeBanner />
						</Grid>
						<Grid
							item
							md={6}
							sm={12}
							xs={12}
							className={[
								classes.p40,
								classes.verticalAlign,
								classes.paddingLRBoxR,
								classes.relative,
							].join(" ")}
						>
							<div className={classes.root}>
								<div>
									<Typography
										variant="h4"
										align="left"
										className={classes.paddingBottom20}
										style={{ fontWeight: "bold" }}
									>
										Login
									</Typography>

									{this.state.error.length > 1 && (
										<Alert severity="error" className={classes.marginBottom20}>
											{this.state.error}
										</Alert>
									)}

									<form autoComplete="off">
										<div
											className={[classes.w100, classes.marginBottom30].join(
												" "
											)}
										>
											<TextField
												id="standard-email-input"
												label="Email"
												type="email"
												autoComplete="emailAddress"
												className={[classes.w100, classes.inputText].join(
													" "
												)}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) =>
													this.setState({
														emailAddress: event.target.value,
													})
												}
											/>
										</div>
										<div className={[classes.w100].join(" ")}>
											<TextField
												id="standard-password-input"
												label="Password"
												type="password"
												autoComplete="password"
												className={[classes.w100, classes.inputText].join(
													" "
												)}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) =>
													this.setState({ password: event.target.value })
												}
											/>
										</div>
										<div className={[classes.inline].join(" ")}>
											<div
												className={[
													classes.w50,
													classes.floatRight,
													classes.marginBottom20,
												].join(" ")}
											>
												<Link
													href="/forgot"
													className={classes.colorTertiary}
												>
													Reset Password
												</Link>
											</div>
										</div>
										<div
											className={[classes.w100, classes.marginBottom5].join(
												" "
											)}
										>
											<Button
												size="large"
												className={[
													classes.w100,
													classes.submitButton,
												].join(" ")}
												onClick={(event) => {
													event.preventDefault();
													this.handleLogin(
														this.state.emailAddress,
														this.state.password
													);
												}}
												type="submit"
											>
												{!this.state.loading ? (
													<>Log In</>
												) : (
													<CircularProgress
														size={30}
														className={classes.colorTertiary}
													/>
												)}
											</Button>
										</div>
									</form>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</ThemeProvider>
		);
	}
}

export default withStyles(styles)(Login);
