import { SideBarItem } from "../../../interfaces/SideBarItem";
import PeopleIcon from "@material-ui/icons/People";

const Users: SideBarItem = {
	name: "Users",
	icon: <PeopleIcon />,
	adminPanelURL: "users",
	fetchAllURL: "users/all",
	fetchSingleURL: "users/:id",
	updateURL: "users/:id",
	deleteURL: "users/:id",
	postURL: "users",
	columns: [
		{ id: "emailAddress", label: "Email Address", type: "Text", isHeadCell: true, required: true },
		{ id: "role", label: "Role", type: "Enum", isHeadCell: true, required: false, enumChoices: ["default", "readOnlyAdmin", "fullAccessAdmin"] },
	],
	orderBy: "name",
	order: "asc",
};

export default Users;
