import { SideBarItem } from "../../../interfaces/SideBarItem";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';


const Videos: SideBarItem = {
	name: "Videos",
	icon: <VideoLibraryIcon />,
	adminPanelURL: "videos",
	fetchAllURL: "videos/all",
	fetchSingleURL: "videos/:id",
	updateURL: "videos/:id",
	deleteURL: "videos/:id",
	postURL: "videos",
	search: true,
	columns: [
		{ id: "headline", label: "Title", type: "Text", isHeadCell: true, required: true },
		{
			id: "datetime",
			label: "Datetime",
            type:"datetime-local",
			isHeadCell: true,
			required: true,
			disabled:true
		}
	],
	orderBy: "datetime",
	order: "desc",
};

export default Videos;
