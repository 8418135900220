import { useHistory } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./App.css";

//AuthProvider
import { AuthProvider } from "./components/auth/authProvider";
import AuthRoute from "./components/auth/authProvider/AuthRoute";

function App() {
	let history = useHistory();

	const handleLogin = () => {
		if (window.location.pathname === "/") {
			history.push("/home");
		}
	};

	const handleLogout = () => {
		history.push("/");
	};

	const checkForExistingToken = () => {
		const expiry = localStorage.getItem("expiry");
		if (expiry) {
			const today = new Date();
			return Math.floor(today.getTime() / 1000) < parseInt(expiry);
		}
		return false;
	};

	const checkForExistingRole = () => {
		const expiry = localStorage.getItem("expiry");
		const role = localStorage.getItem("role") || "default";
		if (expiry) {
			const today = new Date();
			if (Math.floor(today.getTime() / 1000) > parseInt(expiry)) {
				return "default";
			}
		}

		return role;
	};

	const meta = {
		title: process.env.REACT_APP_PRODUCT_NAME,
		description: "",
		canonical: "",
		image: process.env.REACT_APP_PRODUCT_IMAGE,
		meta: {
			charset: "utf-8",
			name: {
				keywords: "",
			},
			itemProp: {
				name: "",
				description: "",
				image: "",
			},
			property: {
				"og:title": process.env.REACT_APP_PRODUCT_NAME,
				"og:image": process.env.REACT_APP_PRODUCT_IMAGE,
				"og:url": process.env.REACT_APP_PRODUCT_URL,
				"og:site_name": process.env.REACT_APP_PRODUCT_NAME,
				"og:description": "",
				"twitter:site": "",
				"twitter:title": "",
				"twitter:card": "summary_large_image",
				"twitter:description": "",
				"twitter:image": process.env.REACT_APP_PRODUCT_IMAGE,
			},
		},
	};

	return (
		<DocumentMeta {...meta}>
			<AuthProvider onLogin={handleLogin} onLogout={handleLogout} defaultAuthenticated={checkForExistingToken()} defaultRole={checkForExistingRole()}>
				<AuthRoute />
			</AuthProvider>
		</DocumentMeta>
	);
}

export default App;
