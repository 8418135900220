import { SideBarItem } from "../../../interfaces/SideBarItem";
import TabUnselectedIcon from "@material-ui/icons/TabUnselected";

const RemoteTab: SideBarItem = {
	name: "Remote Tab",
	icon: <TabUnselectedIcon />,
	adminPanelURL: "remoteTabs",
	fetchAllURL: "remoteTabs/all",
	fetchSingleURL: "remoteTabs/:id",
	updateURL: "remoteTabs/:id",
	deleteURL: "remoteTabs/:id",
	postURL: "remoteTabs",
	columns: [
		{ id: "title", label: "Title", type: "Text", isHeadCell: true, required: true, disabled:true },
		{ id: "url", label: "URL", type: "Text", isHeadCell: false, required: false },
		{ id: "androidURL", label: "Android URL", type: "Text", isHeadCell: false, required: false }
	],
	orderBy: "name",
	order: "asc",
};

export default RemoteTab;
