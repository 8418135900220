import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
	interface Theme {
		borders?: any;
		customColors?: any;
	}
	// allow configuration using `createMuiTheme`
	interface ThemeOptions {
		borders?: any;
		customColors?: any;
	}
}

const palette = {
	primary: { main: String(process.env.REACT_APP_PRIMARY_COL) },
	secondary: { main: String(process.env.REACT_APP_SECONDARY_COL) }
};

const customColors = {
	gradient: {
		main: `linear-gradient(to left,  ${palette.primary.main}, ${palette.secondary.main})`,
	},
};

const borders = {
	light: { borderBottom: "0.1px solid #19233F" },
};

const typography = {
	fontFamily: [
		"-apple-system",
		"BlinkMacSystemFont",
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
};

export default createMuiTheme({
	palette,
	typography,
	borders,
	customColors,
});
