import React from "react";
import { createStyles, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

//SEN components
import theme from "../../theme";
import { post } from "../../dataProvider";

const styles = () =>
	createStyles({
		root: {
			width: "100%",
			padding: "0px 40px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			borderRadius: "5px",
			color: "white",
			"@media (max-width: 780px)": {
				margin: "0px",
				padding: "0px 0px",
			},
		},
		fullHeight: {
			height: "100vh",
			padding: "0px",
		},
		w100: {
			width: "100%",
		},
		w50: {
			width: "50%",
		},
		p40: {
			padding: "40px",
		},
		floatLeft: {
			display: "inline",
			float: "left",
		},
		floatRight: {
			display: "inline",
			textAlign: "right",
			float: "right",
		},
		inline: {
			display: "inline",
		},
		headerBold: {
			fontWeight: 800,
			fontSize: "60px",
		},
		paddingLR7Percent: {
			padding: "0 7.5%",
			"@media (max-width: 1280px)": {
				padding: "40px 7.5%",
			},
			"@media (max-width: 700px)": {
				paddingBottom: "0px",
			},
		},
		paddingBottom20: {
			paddingBottom: "20px",
		},
		marginBottom5: {
			marginBottom: "5px",
		},
		marginBottom20: {
			marginBottom: "20px",
		},
		marginBottom30: {
			marginBottom: "30px",
		},
		verticalAlign: {
			display: "flex",
			alignItems: "center",
		},
		textCenter: { textAlign: "center" },
		submitButton: {
			background: "transparent",
			color: "white",
			border: "1px solid white",
			fontWeight: "bold",
			fontSize: "22px",
			height: "60px",
			textTransform: "none",
			"&:hover": {
				background: "white",
				border: "1px solid " + theme.palette.primary.main,
				color: theme.palette.primary.main,
			},
		},
		secondaryButton: {
			color: theme.palette.primary.main,
			fontWeight: "bold",
			fontSize: "22px",
			height: "60px",
			textTransform: "none",
		},
		inputText: {
			borderColor: "white",
			color: "white",
			borderWidth: "20px",

			"& label": {
				color: "white",
				fontSize: "25px",
			},
			"& .Mui-focused": {
				color: "white",
			},
			"& .MuiInputBase-root": {
				color: "white",
				height: "60px",
			},
			"& .MuiInput-underline:after": {
				color: "white",
				borderBottomColor: "white",
			},
			"& .MuiOutlinedInput-root": {
				fontSize: "25px",

				"& fieldset": {
					color: "white",
					borderColor: "white",
				},
				"&:hover fieldset": {
					color: "white",
					borderColor: "white",
				},
				"&.Mui-focused fieldset": {
					color: "white",
					borderColor: "white",
				},
			},
		},
		bgImage: {
			backgroundImage: theme.customColors.gradient.main,
			backgroundRepeat: "no-repeat",
			backgroundAttachment: "fixed",
			backgroundSize: "cover",
			"@media (max-width: 700px)": {
				backgroundImage: theme.customColors.gradient.main,
			},
		},
		hideMobile: {
			"@media (max-width: 780px)": {
				display: "none",
			},
		},
		showMobile: {
			display: "none",
			"@media (max-width: 780px)": {
				display: "block",
			},
		},
		overflowAuto: {
			overflowY: "auto",
			overflowX: "hidden",
		},
	});

interface ResetPasswordProps {
	classes: any;
}

interface ResetPasswordState {
	emailAddress: string;
	loading: boolean;
	success: boolean;
}

class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
	constructor(props: ResetPasswordProps) {
		super(props);
		this.state = { emailAddress: "", loading: false, success: false };
		this.resetPassword = this.resetPassword.bind(this);
	}

	async resetPassword() {
		this.setState({ loading: true });
		try {
			await post(`${process.env.REACT_APP_API_URL}/auth/triggerResetPassword`, this.state);
			this.setState({ loading: false, success: true });
		} catch (err) {
			this.setState({ loading: false });
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className={classes.bgImage}>
					<Container
						maxWidth="md"
						className={[classes.fullHeight, classes.verticalAlign].join(" ")}
					>
						<div className={classes.root}>
							<div className={classes.textCenter}>
								<img
									src={process.env.REACT_APP_PRODUCT_IMAGE}
									alt={" "}
									style={{ width: "200px", marginTop: "20px" }}
								/>
							</div>
							<Typography
								variant="h4"
								align="left"
								className={classes.paddingTop20}
								style={{ fontWeight: "bold" }}
							>
								Password Reset
							</Typography>
							<p>Enter your email below to reset your password</p>
							{this.state.success && (
								<Alert severity="success" className={classes.marginBottom20}>
									Please check your email and follow the instructions to reset
									your password
								</Alert>
							)}
							<form autoComplete="off">
								<div className={[classes.w100, classes.marginBottom20].join(" ")}>
									<TextField
										id="standard-email-input"
										label="Email"
										type="email"
										className={[classes.w100, classes.inputText].join(" ")}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(event) =>
											this.setState({ emailAddress: event.target.value })
										}
									/>
								</div>
							</form>
							<div className={[classes.w100, classes.marginBottom20].join(" ")}>
								<Button
									size="large"
									className={[classes.w100, classes.submitButton].join(" ")}
									onClick={(event) => {
										event.preventDefault();
										this.resetPassword();
									}}
								>
									{!this.state.loading ? (
										<>Reset Password</>
									) : (
										<CircularProgress size={30} />
									)}
								</Button>
							</div>
						</div>
					</Container>
				</div>
			</ThemeProvider>
		);
	}
}

export default withStyles(styles)(ResetPassword);
