import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//Auth
import Login from "../sub-components/Login";
//import Register from "../sub-components/Register";
import ResetPassword from "../sub-components/ResetPassword";
import TokenResetPassword from "../sub-components/TokenResetPassword";
import Home from "../../dashboard/Home";
import Admin from "../../admin/Admin";
import NavBar from "../../dashboard/NavBar";

import { AuthContext } from "./AuthContext";

interface AuthRouteProps {
	component?: any;
}

interface AuthRouteState {}

class AuthRoute extends React.Component<AuthRouteProps, AuthRouteState> {
	static contextType = AuthContext;

	render() {
		const { authenticated, role } = this.context;

		/* Add to !authenticated: <Route exact path="/register" component={Register} />*/

		if (!authenticated) {
			return (
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/forgot" component={ResetPassword} />
					<Route exact path="/reset-password/:resetToken" component={TokenResetPassword} />
					<Redirect to="/" />
				</Switch>
			);
		}

		return (
			<>
				<NavBar />
				<Switch>
					<Route exact path="/forgot" component={ResetPassword} />
					<Route exact path="/home" component={Home} />
					{["readOnlyAdmin", "fullAccessAdmin"].includes(role) && (
						<>
							<Route path="/admin/:cat?/:view?/:id?" component={Admin} />
						</>
					)}
				</Switch>
			</>
		);
	}
}

export default AuthRoute;
