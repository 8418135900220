import React from "react";
import { createStyles, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

//SEN components
import theme from "../../theme";
import { put } from "../../dataProvider";

const styles = () =>
	createStyles({
		root: {
			width: "100%",
			padding: "0px 40px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			borderRadius: "5px",
			color: "white",
			"@media (max-width: 780px)": {
				margin: "0px",
				padding: "0px 0px",
			},
		},
		fullHeight: {
			height: "100vh",
			padding: "0px",
		},
		w100: {
			width: "100%",
		},
		w50: {
			width: "50%",
		},
		p40: {
			padding: "40px",
		},
		floatLeft: {
			display: "inline",
			float: "left",
		},
		floatRight: {
			display: "inline",
			textAlign: "right",
			float: "right",
		},
		inline: {
			display: "inline",
		},
		headerBold: {
			fontWeight: 800,
			fontSize: "60px",
		},
		paddingLR7Percent: {
			padding: "0 7.5%",
			"@media (max-width: 1280px)": {
				padding: "40px 7.5%",
			},
			"@media (max-width: 700px)": {
				paddingBottom: "0px",
			},
		},
		paddingBottom20: {
			paddingBottom: "20px",
		},
		marginBottom5: {
			marginBottom: "5px",
		},
		marginBottom20: {
			marginBottom: "20px",
		},
		marginBottom30: {
			marginBottom: "30px",
		},
		verticalAlign: {
			display: "flex",
			alignItems: "center",
		},
		textCenter: { textAlign: "center" },
		submitButton: {
			background: "transparent",
			color: "white",
			border: "1px solid white",
			fontWeight: "bold",
			fontSize: "22px",
			height: "60px",
			textTransform: "none",
			"&:hover": {
				background: "white",
				border: "1px solid " + theme.palette.primary.main,
				color: theme.palette.primary.main,
			},
		},
		secondaryButton: {
			color: theme.palette.primary.main,
			fontWeight: "bold",
			fontSize: "22px",
			height: "60px",
			textTransform: "none",
		},
		inputText: {
			borderColor: "white",
			color: "white",
			borderWidth: "20px",

			"& label": {
				color: "white",
				fontSize: "25px",
			},
			"& .Mui-focused": {
				color: "white",
			},
			"& .MuiInputBase-root": {
				color: "white",
				height: "60px",
			},
			"& .MuiInput-underline:after": {
				color: "white",
				borderBottomColor: "white",
			},
			"& .MuiOutlinedInput-root": {
				fontSize: "25px",

				"& fieldset": {
					color: "white",
					borderColor: "white",
				},
				"&:hover fieldset": {
					color: "white",
					borderColor: "white",
				},
				"&.Mui-focused fieldset": {
					color: "white",
					borderColor: "white",
				},
			},
		},
		bgImage: {
			backgroundImage: theme.customColors.gradient.main,
			backgroundRepeat: "no-repeat",
			backgroundAttachment: "fixed",
			backgroundSize: "cover",
			"@media (max-width: 700px)": {
				backgroundImage: theme.customColors.gradient.main,
			},
		},
		hideMobile: {
			"@media (max-width: 780px)": {
				display: "none",
			},
		},
		showMobile: {
			display: "none",
			"@media (max-width: 780px)": {
				display: "block",
			},
		},
		overflowAuto: {
			overflowY: "auto",
			overflowX: "hidden",
		},
	});

interface TokenResetPasswordProps {
	classes: any;
}

interface TokenResetPasswordState {
	password: string;
	password2: string;
	loading: boolean;
	success: boolean;
	error: string;
}

class TokenResetPassword extends React.Component<TokenResetPasswordProps, TokenResetPasswordState> {
	constructor(props: TokenResetPasswordProps) {
		super(props);
		this.state = { password: "", password2: "", loading: false, success: false, error: "" };
		this.TokenResetPassword = this.TokenResetPassword.bind(this);
	}

	async TokenResetPassword() {
		this.setState({ loading: true });
		const token = window.location.pathname.split("reset-password/")[1];

		if (this.state.password !== this.state.password2) {
			this.setState({ loading: false, error: "Your inputs don't match!" });
			return;
		}

		if (this.state.password.length < 6) {
			this.setState({ loading: false, error: "Please set a stronger password" });
			return;
		}

		try {
			await put(`${process.env.REACT_APP_API_URL}/users/${token}/passwordToken`, {
				newValue: this.state.password,
			});
			this.setState({ loading: false, success: true, error: "" }, () => {
				setTimeout(() => (window.location.pathname = "/"), 2000);
			});
		} catch (err) {
			this.setState({ loading: false, error: "Error requesting password update" });
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className={classes.bgImage}>
					<Container maxWidth="md" className={[classes.fullHeight, classes.verticalAlign].join(" ")}>
						<div className={classes.root}>
							<div className={classes.textCenter}>
								<img src={process.env.REACT_APP_PRODUCT_IMAGE} alt={" "} style={{ width: "200px", marginTop: "20px" }} />
							</div>
							<Typography variant="h4" align="left" className={classes.paddingTopBottom10} style={{ fontWeight: "bold", marginBottom: "20px" }}>
								Password Reset
							</Typography>
							{this.state.success && (
								<Alert severity="success" className={classes.marginBottom20}>
									Password successfully changed. You will be redirected
								</Alert>
							)}
							{this.state.error.length > 1 && (
								<Alert severity="error" className={classes.marginBottom20}>
									{this.state.error}
								</Alert>
							)}
							<form autoComplete="off">
								<div className={[classes.w100, classes.marginBottom20].join(" ")}>
									<TextField
										label="Enter a new password"
										type="password"
										className={[classes.w100, classes.inputText].join(" ")}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(event) => this.setState({ password: event.target.value })}
									/>
								</div>
								<div className={[classes.w100, classes.marginBottom20].join(" ")}>
									<TextField
										label="Confirm your new password"
										type="password"
										className={[classes.w100, classes.inputText].join(" ")}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(event) => this.setState({ password2: event.target.value })}
									/>
								</div>
							</form>
							<div className={[classes.w100, classes.marginBottom20].join(" ")}>
								<Button
									size="large"
									className={[classes.w100, classes.submitButton].join(" ")}
									onClick={(event) => {
										event.preventDefault();
										this.TokenResetPassword();
									}}
								>
									{!this.state.loading ? <>Reset Password</> : <CircularProgress size={30} />}
								</Button>
							</div>
						</div>
					</Container>
				</div>
			</ThemeProvider>
		);
	}
}

export default withStyles(styles)(TokenResetPassword);
