import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";

//SEN Components
import { SideBarItem } from "../../interfaces/SideBarItem";
import { put, post, get } from "../../dataProvider";
import ImageUploader from "../sub-components/ImageUploader";

const styles = () =>
	createStyles({
		root: {
			background: "white",
			color: "black",
			borderRadius: "5px",
			padding: "20px",
			marginLeft: "20px",
			boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
		},
		rootMobile: {
			background: "white",
			color: "black",
			borderRadius: "5px",
			padding: "20px",
			boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
		},
		marginBottom20: {
			marginBottom: "20px",
		},
		marginTopBottom20: {
			margin: "20px 0px",
		},
		textField: {
			marginBottom: "20px",
			marginRight: "20px",
		},
		maxWidth50: {
			maxWidth: "50%",
			width: "400px",
		},
		w100: {
			width: "100%",
		},
		p10: {
			padding: "10px",
		},
		inputBox: {
			borderRadius: "5px 5px 0px 0px",
			width: "100%",
			marginBottom: "10px",
		},
		inputPadding: {
			paddingTop: "10px",
			paddingLeft: "10px",
		},
		filled: {
			background: "#E8E8E8",
			paddingTop: "5px",
			paddingLeft: "10px",
		},
	});

interface CreateViewProps {
	classes?: any;
	setEdit?: (id: string) => void;
	element: SideBarItem;
	isMobile: boolean;
}

interface CreateViewState {
	uploadingImage: number;
	newData: any;
	imageUploading: string;
	selectableOptions: any;
	selectedOptions: any;
}

class CreateView extends React.Component<CreateViewProps, CreateViewState> {
	constructor(props: CreateViewProps) {
		super(props);
		this.state = {
			uploadingImage: 0,
			newData: {},
			imageUploading: "",
			selectableOptions: {},
			selectedOptions: {},
		};
		this.getSignedUrl = this.getSignedUrl.bind(this);
		this.onUploadProgress = this.onUploadProgress.bind(this);
		this.onUploadFinish = this.onUploadFinish.bind(this);
		this.onUploadError = this.onUploadError.bind(this);
		this.saveView = this.saveView.bind(this);
		this.updateValues = this.updateValues.bind(this);
		this.updateDateTimeDate = this.updateDateTimeDate.bind(this);
		this.updateDateTimeTime = this.updateDateTimeTime.bind(this);
	}

	async getSignedUrl(file: any, callback: any) {
		const data = await put(`${process.env.REACT_APP_API_URL}/s3`, {
			objectName: file.name,
			contentType: file.type,
		});
		this.setState({ imageUploading: data.data.publicUrl });
		callback(data.data);
	}

	onUploadProgress(prog: number) {
		this.setState({ uploadingImage: prog });
	}

	onUploadFinish(url: string, colId: any) {
		let currentStateOfNewData = this.state.newData;
		currentStateOfNewData[colId] = url;
		this.setState({
			uploadingImage: 100,
			imageUploading: "",
			newData: currentStateOfNewData,
		});
	}

	onUploadError(e: any) {}

	async saveView() {
		for (const column of this.props.element.columns!) {
			if (column.required) {
				if (!this.state.newData[column.id]) {
					alert(`Please enter a value for the ${column.label}`);
					return;
				}
			}
		}

		//Format ONLY IF PASSES TEST
		for (const column of this.props.element.columns!) {
			if (column.type === "Timestamp") {
				if (this.state.newData[column.id]) {
					this.state.newData[column.id] = Math.floor(new Date(this.state.newData[column.id]).getTime() / 1000);
				}
			}
		}

		const newRecord = await post(`${process.env.REACT_APP_API_URL}/${this.props.element.postURL}`, this.state.newData);
		this.props.setEdit!(newRecord.data.id);
	}

	updateValues(id: any, newValue: any) {
		let currentState = this.state.newData;
		currentState[String(id)] = newValue;
		this.setState({ newData: currentState });
	}

	updateDateTimeDate(id: any, newValue: any) {
		if (newValue instanceof Date && !isNaN(newValue as any)) {
			let currentState = this.state.newData;
			let currentDTimeDate = new Date(currentState[String(id)]);

			if (currentDTimeDate instanceof Date && isNaN(currentDTimeDate as any)) {
				currentDTimeDate = new Date();
			}

			let newDTimeDate = new Date(newValue);

			newDTimeDate.setHours(currentDTimeDate.getHours());
			newDTimeDate.setMinutes(currentDTimeDate.getMinutes());

			currentState[String(id)] = newDTimeDate;
			this.setState({ newData: currentState });
		}
	}

	updateDateTimeTime(id: any, newValue: any) {
		if (newValue instanceof Date && !isNaN(newValue as any)) {
			let currentState = this.state.newData;
			let currentDTimeDate = new Date(currentState[String(id)]);

			if (currentDTimeDate instanceof Date && isNaN(currentDTimeDate as any)) {
				currentDTimeDate = new Date();
			}

			let newDTimeDate = new Date(newValue);

			currentDTimeDate.setHours(newDTimeDate.getHours());
			currentDTimeDate.setMinutes(newDTimeDate.getMinutes());

			currentState[String(id)] = currentDTimeDate;
			this.setState({ newData: currentState });
		}
	}

	async componentDidMount() {
		let selectableOptions = this.state.selectableOptions;
		let newData = this.state.newData;

		for (const col of this.props.element.columns!) {
			if (["ManyToMany", "ReferenceInput"].includes(col.type)) {
				const response = await get(`${process.env.REACT_APP_API_URL}/${col.referenceInputData!.referenceURL}`);
				let data = [];
				newData[col.id] = [];
				for (const row of response.data) {
					data.push({
						id: row[col.referenceInputData!.referenceID],
						name: row[col.referenceInputData!.referenceName],
					});
				}
				selectableOptions[col.id] = data;
			}
		}
		this.setState({ selectableOptions, newData });
	}

	render() {
		const { classes, element, isMobile } = this.props;
		return (
			<>
				<Container maxWidth="xl" className={!isMobile ? classes.root : classes.rootMobile}>
					<div className={classes.marginBottom20}>
						<h1
							style={{
								marginTop: 0,
								width: "80%",
								float: "left",
							}}
						>
							Create {element.name}
						</h1>
						<IconButton aria-label="save" style={{ float: "right" }} onClick={() => this.saveView()}>
							<SaveIcon />
						</IconButton>
					</div>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							{(element.columns || []).map((column, index) => (
								<>
									{column.type === "Text" && (
										<TextField
											label={column.label}
											variant="filled"
											className={classes.inputBox}
											onChange={(event) => this.updateValues(column.id, event.target.value)}
										/>
									)}
									{column.type === "TextArea" && (
										<TextField
											variant="filled"
											multiline
											rows={5}
											label={column.label}
											className={classes.inputBox}
											onChange={(event) => this.updateValues(column.id, event.target.value)}
										/>
									)}
									{column.type === "Number" && (
										<TextField
											variant="filled"
											label={column.label}
											type="number"
											InputLabelProps={{ shrink: true }}
											className={classes.inputBox}
											onChange={(event) => this.updateValues(column.id, event.target.value)}
										/>
									)}
									{column.type === "Enum" && (
										<FormControl className={classes.inputBox}>
											<InputLabel id={`${column.label}-label`}>{column.label}</InputLabel>
											<Select
												className={classes.inputBox}
												labelId={`${column.label}-label`}
												onChange={(event) => this.updateValues(column.id, event.target.value)}
											>
												{column.enumChoices!.map((item, index) => (
													<MenuItem value={String(item)}>{item}</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
									{column.type === "Date" && (
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													format="dd/MM/yyyy"
													margin="normal"
													id="date-picker-inline"
													label={column.label}
													value={this.state.newData[column.id]}
													onChange={(date) => this.updateValues(column.id, date)}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													InputLabelProps={{
														shrink: true,
														className: classes.inputPadding,
													}}
													className={[classes.inputBox, classes.filled].join(" ")}
												/>
											</div>
										</MuiPickersUtilsProvider>
									)}
									{column.type === "Timestamp" && (
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													format="dd/MM/yyyy"
													margin="normal"
													id="date-picker-inline"
													label={column.label}
													value={this.state.newData[column.id]}
													onChange={(date) => this.updateDateTimeDate(column.id, date)}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													InputLabelProps={{
														shrink: true,
														className: classes.inputPadding,
													}}
													className={[classes.inputBox, classes.filled].join(" ")}
												/>
											</div>
											<div>
												<KeyboardTimePicker
													margin="normal"
													id="time-picker"
													variant="inline"
													label={column.label}
													value={this.state.newData[column.id]}
													onChange={(time) => this.updateDateTimeTime(column.id, time)}
													KeyboardButtonProps={{
														"aria-label": "change time",
													}}
													InputLabelProps={{
														shrink: true,
														className: classes.inputPadding,
													}}
													className={[classes.inputBox, classes.filled].join(" ")}
												/>
											</div>
										</MuiPickersUtilsProvider>
									)}
									{column.type === "Image" && (
										<div
											style={{
												width: "200px",
												height: "auto",
												marginBottom: "10px",
											}}
										>
											{this.state.newData[column.id] && (
												<img style={{ width: "100%", height: "auto" }} src={this.state.newData[column.id] || ""} alt="Img" />
											)}

											{this.state.uploadingImage === 0 || this.state.uploadingImage === 100 ? (
												<ImageUploader addUrlToState={(url) => this.onUploadFinish(url, column.id)} />
											) : (
												<CircularProgress />
											)}
										</div>
									)}
									{column.type === "ManyToMany" && (
										<FormControl className={classes.inputBox}>
											<InputLabel id="mutiple-chip-label">{column.label}</InputLabel>
											<Select
												labelId="mutiple-chip-label"
												id="mutiple-chip"
												multiple
												value={this.state.newData[column.id] || []}
												onChange={(event: any) => this.updateValues(column.id, event.target.value)}
												input={<Input id="select-multiple-chip" />}
												renderValue={(selected) => (
													<div className={classes.chips}>
														{this.state.selectableOptions[column.id] &&
															(selected as string[]).map((value) => {
																return (
																	<Chip
																		key={value}
																		label={
																			this.state.selectableOptions[column.id].find((x: any) => value === x.id)
																				.name
																		}
																		className={classes.chip}
																	/>
																);
															})}
													</div>
												)}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 48 * 4.5 + 8,
															width: 250,
														},
													},
												}}
											>
												{this.state.selectableOptions[column.id] &&
													this.state.selectableOptions[column.id].map((item: any) => (
														<MenuItem key={item.id} value={item.id}>
															{item.name}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									)}
									{column.type === "ReferenceInput" && (
										<FormControl className={classes.inputBox}>
											<InputLabel id={`${String(column.id)}-label`}>{column.label}</InputLabel>
											<Select
												labelId={`${String(column.id)}-label`}
												value={this.state.newData[column.id] || []}
												onChange={(event: any) => this.updateValues(column.id, event.target.value)}
											>
												{this.state.selectableOptions[column.id] &&
													this.state.selectableOptions[column.id].map((item: any) => (
														<MenuItem key={item.id} value={item.id}>
															{item.name}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									)}
								</>
							))}
						</Grid>
					</Grid>
					<Button
						aria-label="save"
						variant="contained"
						startIcon={<SaveIcon />}
						style={{ float: "left", boxShadow: "none" }}
						onClick={() => this.saveView()}
					>
						Save
					</Button>
				</Container>
			</>
		);
	}
}

export default withStyles(styles)(CreateView);
