import React from "react";

export type IAuthContext = {
	authenticated: boolean;
	setAuthenticated: (isAuthenticated: boolean) => void;
	role: string;
	setRole: (role: string) => void;
};

const noop = () => {};

export const AuthContext = React.createContext<IAuthContext>({
	authenticated: false,
	setAuthenticated: noop,
	role: "default",
	setRole: noop,
});
