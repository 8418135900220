import { SideBarItem } from "../../../interfaces/SideBarItem";
import LiveTvIcon from "@material-ui/icons/LiveTv";

const LiveVideoStream: SideBarItem = {
	name: "Live Video Stream",
	icon: <LiveTvIcon />,
	adminPanelURL: "LiveVideoStreams",
	fetchAllURL: "LiveVideoStreams/all",
	fetchSingleURL: "LiveVideoStreams/:id",
	updateURL: "LiveVideoStreams/:id",
	deleteURL: "LiveVideoStreams/:id",
	postURL: "LiveVideoStreams",
	columns: [
		{ id: "title", label: "Title", type: "Text", isHeadCell: true, required: true },
		{ id: "description", label: "Description", type: "TextArea", isHeadCell: false, required: true },
		{ id: "image", label: "Image", type: "Image", isHeadCell: false, required: true },
		{ id: "start", label: "Start", type: "Timestamp", isHeadCell: true, required: true },
		{ id: "end", label: "End", type: "Timestamp", isHeadCell: true, required: true },
	],
	orderBy: "name",
	order: "asc",
	blockCreate: false,
	blockDelete: false,
};

export default LiveVideoStream;
