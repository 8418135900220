import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

//SEN components
import theme from "../theme";

const styles = () =>
	createStyles({
		backdrop: {
			background: "transparent",
		},
		bottomDiv: {
			position: "absolute",
			width: "200px",
			height: "30px",
			bottom: "0px",
			left: "50%",
			marginLeft: "-100px",
		},
		link: {
			textDecoration: "none",
			marginRight: "7px",
			color: theme.palette.primary.main,
		},
	});

interface HomeProps {
	classes: any;
}

interface HomeState {}

class Home extends React.Component<HomeProps, HomeState> {
	render() {
		//const { classes } = this.props;
		window.location.pathname = "/admin";

		return <></>;
	}
}

export default withStyles(styles)(Home);
