import React, { FC } from "react";
import axios from "axios";
import { ChangeEvent } from "react";

interface ImageUploadProps {
	addUrlToState: (url: string) => void;
}

const ImageUpload: FC<ImageUploadProps> = ({ addUrlToState }) => {
	function upload(event: ChangeEvent<HTMLInputElement>) {
		event.preventDefault();

		let formData = new FormData();
		const files = event.currentTarget.files ?? [];

		if (files.length > 1 || files.length === 0 || !files[0].type.includes("image")) {
			event.currentTarget.value = "";
			return alert("Please select one image file only");
		} else {
			formData.append("file", files[0]);
		}

		axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_URL}/s3/uploadImage`,
			data: formData,
			headers: { "Content-Type": "multipart/form-data", authorization: localStorage.getItem("authorization") ?? "" },
		})
			.then((res) => {
				addUrlToState(res.data.fileUrl);
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	return (
		<div style={{ width: "250px" }}>
			<form>
				<input type="file" name="file" onChange={upload}></input>
			</form>
		</div>
	);
};

export default ImageUpload;
