import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

//SEN components
import { SideBarItem } from "../../interfaces/SideBarItem";

const styles = () =>
	createStyles({
		white: {
			background: "white",
			boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
		},
		padding20: {
			padding: "20px",
		},
		w100: {
			width: "100%",
			margin: "0px 10px",
		},
		rounded: {
			borderRadius: "5px",
		},

		mb30: {
			marginBottom: "30px",
		},
	});

interface SubMenuProps {
	classes?: any;
	sideBar: SideBarItem[];
	updateTable: (page: number) => void;
	isMobile: boolean;
	activePage: number;
}

interface SubMenuState {}

class SubMenu extends React.Component<SubMenuProps, SubMenuState> {
	render() {
		const { classes, sideBar, isMobile, activePage } = this.props;
		const gridWidth = isMobile ? 12 : 3;

		return (
			<Grid className={[classes.white, classes.rounded, classes.mb30].join(" ")} style={isMobile ? { marginLeft: "0px" } : {}} container item xs={gridWidth}>
				<List component="nav" className={classes.w100} subheader={<ListSubheader component="div">Menu</ListSubheader>}>
					{sideBar.map((row, index) => {
						return (
							<ListItem button onClick={() => this.props.updateTable(index)} style={index === activePage ? { background: "#E9E9E9", borderRadius: "5px", fontWeight: "bold" } : {}}>
								<ListItemIcon>{row.icon}</ListItemIcon>
								<ListItemText style={{ color: "rgba(0, 0, 0, 0.87)" }} primary={row.name} />
							</ListItem>
						);
					})}
				</List>
			</Grid>
		);
	}
}

export default withStyles(styles)(SubMenu);
