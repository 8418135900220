import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

//SEN components
import { AuthContext } from "../authProvider";

const styles = () =>
	createStyles({
		w100: {
			width: "100%",
		},
		textCenter: { textAlign: "center" },
		mainLogo: {
			width: "80%",
			maxWidth: "250px",
			"@media (max-width: 700px)": {
				maxWidth: "150px",
			},
		},
		regularText: {
			fontWeight: 500,
			fontSize: "20px",
			color: "white",
			"@media (max-width: 700px)": {
				fontSize: "15px",
			},
		},
		marginBottom20: {
			marginBottom: "20px",
		},
		marginBottom30: {
			marginBottom: "30px",
		},
		marginBottom70: {
			marginBottom: "70px",
			"@media (max-width: 1500px)": {
				marginBottom: "30px",
			},
		},
		marginBottom160: {
			marginBottom: "160px",
			"@media (max-width: 1500px)": {
				marginBottom: "30px",
			},
		},
		marginTop0: {
			marginTop: 0,
		},
		paddingLR60: {
			padding: "0px 60px",
			"@media (max-width: 700px)": {
				padding: "0 20px",
			},
		},
	});

interface HomeBannerProps {
	classes: any;
}

interface HomeBannerState {}

class HomeBanner extends React.Component<HomeBannerProps, HomeBannerState> {
	static contextType = AuthContext;

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.w100}>
				<div className={[classes.textCenter].join(" ")}>
					<img src={process.env.REACT_APP_PRODUCT_IMAGE} alt={"logo"} className={[classes.mainLogo, classes.marginBottom70].join(" ")} />
					<p className={[classes.regularText, classes.marginBottom160, classes.paddingLR60, classes.marginTop0].join(" ")}>{process.env.REACT_APP_PRODUCT_NAME}</p>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(HomeBanner);
