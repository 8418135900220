const axios = require("axios");

async function login(emailAddress: string, password: string) {
	try {
		const loginResponse = await post(`${process.env.REACT_APP_API_URL}/auth/attempts`, {
			emailAddress: emailAddress,
			password: password,
		});
		localStorage.setItem("email", emailAddress);
		localStorage.setItem("id", loginResponse.data.token.ownerID);
		localStorage.setItem("authorization", loginResponse.data.token.key);
		localStorage.setItem("name", loginResponse.data.name);
		localStorage.setItem("role", loginResponse.data.role);
		localStorage.setItem("expiry", loginResponse.data.token.created + loginResponse.data.token.expiry);
		return loginResponse;
	} catch (err:any) {
		throw Error(err);
	}
}

async function register(emailAddress: string, password: string, phoneNumber: string, name: string) {
	try {
		await post(`${process.env.REACT_APP_API_URL}/users`, {
			emailAddress,
			password,
			phoneNumber,
			name,
		});
		return login(emailAddress, password);
	} catch (err:any) {
		throw Error(err);
	}
}

function logout() {
	localStorage.clear();
	return false;
}

async function post(url: string, body: object) {
	const auth = localStorage.getItem("authorization");
	let response;
	try {
		if (auth) {
			response = await axios.post(url, body, { headers: { authorization: auth } });
		} else {
			response = await axios.post(url, body);
		}
	} catch (err:any) {
		throw Error(err);
	}
	return response;
}

async function get(url: string) {
	const auth = localStorage.getItem("authorization");
	let response;

	try {
		if (auth) {
			response = await axios.get(url, { headers: { authorization: auth } });
		} else {
			response = await axios.get(url);
		}
	} catch (err:any) {
		throw Error(err);
	}

	return response;
}

async function put(url: string, body: object) {
	const auth = localStorage.getItem("authorization");
	let response;

	try {
		if (auth) {
			response = await axios.put(url, body, { headers: { authorization: auth } });
		} else {
			response = await axios.put(url, body);
		}
	} catch (err:any) {
		throw Error(err);
	}

	return response;
}

async function apiDelete(url: string) {
	const auth = localStorage.getItem("authorization");
	let response;

	try {
		if (auth) {
			response = await axios.delete(url, { headers: { authorization: auth } });
		} else {
			response = await axios.delete(url);
		}
	} catch (err:any) {
		throw Error(err);
	}

	return response;
}

export { login, logout, register, post, get, put, apiDelete };
