import React from "react";
import { usePrevious } from "./usePrevious";
import { usePreviousAdmin } from "./usePreviousAdmin";

import { AuthContext } from "./AuthContext";

export type AuthProviderProps = {
	defaultAuthenticated?: boolean;
	defaultRole?: string;
	onLogin?: () => void;
	onLogout?: () => void;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ defaultAuthenticated = false, defaultRole = "default", onLogin, onLogout, children }) => {
	const [authenticated, setAuthenticated] = React.useState(defaultAuthenticated);
	const [role, setRole] = React.useState(defaultRole);

	const previousAuthenticated = usePrevious(authenticated);
	const previousAdmin = usePreviousAdmin(role);

	React.useEffect(() => {
		if (!previousAuthenticated && authenticated) {
			onLogin && onLogin();
		}
	}, [previousAuthenticated, authenticated, previousAdmin, role, onLogin]);

	React.useEffect(() => {
		const expiryTimestamp = parseInt(String(localStorage.getItem("expiry")));
		if ((previousAuthenticated && !authenticated) || Date.now() < expiryTimestamp) {
			onLogout && onLogout();
		}
	}, [previousAuthenticated, authenticated, previousAdmin, role, onLogout]);

	const contextValue = React.useMemo(
		() => ({
			authenticated,
			setAuthenticated,
			role,
			setRole,
		}),
		[authenticated, role]
	);

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
